$(document).on('media.modal.ready', videoBlock);
console.log('testing');

function videoBlock () {
    var youtubeIframeHtml = $(/* html */`
        <iframe class="embed-responsive-item" src="" title="YouTube media player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    `);
    
    var videoBlock = $('.video-block');

    videoBlock.each(function () {
        var $vidBlock = $(this);

        // Build youtube thumbnail
        $('<img src="https://img.youtube.com/vi/' + $vidBlock.attr('data-yt-id') + '/0.jpg" loading="lazy" alt="Play video">')
            .addClass('video-block__thumb')
            .prependTo($vidBlock.find('.video-block__media'));

        // Check when to play inline or in modal
        $vidBlock.find('.video-block__thumb').on('click', function (e) {
            $vidThumb = $(this);
            
            if (
                window.matchMedia('(max-width: 991px)').matches ||
                $vidThumb.outerWidth() >= 629 &&
                window.matchMedia('(min-width: 992px)').matches
            ) {
                e.stopPropagation();
                
                // Add youtube iframe with correct source
                youtubeIframeHtml
                    .clone()
                    .attr('src', 'https://www.youtube-nocookie.com/embed/' + $vidBlock.attr('data-yt-id') + '?autoplay=1')
                    .insertAfter($vidThumb);

                $vidThumb
                    .closest('.video-block')
                    .addClass('js-video-playing-inline');

                $vidThumb.remove();
            }
        });
    });
}